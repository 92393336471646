.card-component-tiles {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}

@media (min-width: 1200px) {
    .card-component-tiles {
        justify-content: inherit;
        flex-wrap: nowrap;
    }

    .card-component-tiles {
        justify-content: inherit;
        flex-wrap: nowrap;
        margin-top: 250px;
    }
}

.card-component-tiles .box-shadow {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px 0px;
}

.card-component-tiles .box-shadow.bg-transparent {
    box-shadow: none;
}

.card-component-tiles .hide-text {
    overflow: hidden;
}

.card-component-tiles .card-half .focus-content-2 {
    width: 100%;
}

.card-component-tiles .card-half .focus-content-2 .card-cta-new {
    bottom: 5px;
}

.card-component-tiles .card-half .focus-content-2 .card-text .title {
    line-height: 1;
}

.card-component-tiles .img-background {
    transition: all 1s ease 0s;
    background-size: cover;
}

.card-component-tiles .img-background:hover .link-present.img-background-present {
    transform: scale(1.08, 1.08) translateY(-3.5%);
    opacity: 1;
}

.card-component-tiles .img { 
    height: 50px;
    width: 50px;
    margin-right: 44px ;
    margin-top: 44px ;
    float:right;
}

.card-component-tiles .img-background-present {
    transition: all 1s ease 0s;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: 50% 50%;
}

.card-component-tiles .focus-content-2 {
    width: 100%;
}

.card-component-tiles .focus-content-2 .title {
    padding-top: 5px;
}

@media (min-width: 1200px) {
    .card-component-tiles .focus-content-2 .title {
        margin: 0px;
    }

    .card-component-tiles .focus-content-2 .title {
        margin: 0px;
    }
}

.card-component-tiles .focus-content-2 .card-cta-new {
    position: absolute;
    bottom: 16px;
}

.card-component-tiles .focus-content-2 .card-cta-new.left {
    text-align: left;
}

.card-component-tiles .focus-content-2 .card-cta-new.right {
    text-align: right;
    right: 24px;
}

.card-component-tiles .focus-content-2 .card-cta-new.center {
    text-align: center;
    width: 100%;
    margin-left: -16px;
}

@media (max-width: 480px) {
    .card-component-tiles .focus-content-2 .card-cta-new.center {
        margin-left: -18px;
    }

    .card-component-tiles .focus-content-2 .card-cta-new.center {
        margin-left: -18px;
    }
}

.card-component-tiles .one-card .focus-content-2 .card-cta-new.center, .card-component-tiles .two-card .focus-content-2 .card-cta-new.center {
    margin-left: -33px;
}

@media (max-width: 480px) {
    .card-component-tiles .one-card .focus-content-2 .card-cta-new.center, .card-component-tiles .two-card .focus-content-2 .card-cta-new.center {
        margin-left: -18px;
    }

    .card-component-tiles .one-card .focus-content-2 .card-cta-new.center, .card-component-tiles .two-card .focus-content-2 .card-cta-new.center {
        margin-left: -18px;
    }
}

.card-component-tiles .three-card .focus-content-2 .card-cta-new.center {
    margin-left: -22px;
}

@media (max-width: 480px) {
    .card-component-tiles .three-card .focus-content-2 .card-cta-new.center {
        margin-left: -18px;
    }

    .card-component-tiles .three-card .focus-content-2 .card-cta-new.center {
        margin-left: -18px;
    }
}

.card-component-tiles ul, .card-component-tiles ol {
    font-size: 16px;
}

.card-component-tiles ul li::before, .card-component-tiles ol li::before {
    font-size: 16px;
    text-align: right;
}

.card-component-tiles ol {
    list-style: none;
    counter-reset: ol 0;
    font-size: 16px;
}

.card-component-tiles ol > li::before {
    font-size: 16px;
    left: -45px;
    min-width: 35px;
    counter-increment: ol 1;
    content: counters(ol, ".") ". ";
}

.card-component-tiles ul {
    font-size: 16px;
}

.card-component-tiles ul > li::before {
    left: -35px;
    width: 25px;
    margin-right: -25px;
    font-size: 16px;
}

.card-component-tiles .card-default {
    border-radius: 6px;
    margin: 10px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 376px;
    height: 293px;
    background-color: #11224B;
}

.card-component-tiles .card-default.img-wrapper {
    background-size: cover;
    background-position: 50% 50%;
}

.card-component-tiles .card-default .link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
}

@media (min-width: 700px) {
    .card-component-tiles .card-default {
        margin: 10px;
    }

    .card-component-tiles .card-default {
        margin: 10px;
    }
}

@media (max-width: 1030px) {
    .card-component-tiles .card-default:first-child.four-card {
        margin-bottom: 10px;
    }

    .card-component-tiles .card-default:first-child.three-card {
        margin-bottom: 10px;
    }

    .card-component-tiles .card-default:first-child.four-card {
        margin-bottom: 10px;
    }

    .card-component-tiles .card-default:first-child.three-card {
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) {
    .card-component-tiles .card-default {
        margin: 0px 10px;
    }

    .card-component-tiles .card-default:first-child {
        margin-left: 0px;
    }

    .card-component-tiles .card-default:last-child {
        margin-right: 0px;
    }

    .card-component-tiles .card-default {
        margin: 0px 10px;
    }

    .card-component-tiles .card-default:first-child {
        margin-left: 0px;
    }

    .card-component-tiles .card-default:last-child {
        margin-right: 0px;
    }
}

@media (min-width: 768px) {
    .card-component-tiles .card-default.four-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.four-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default.four-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.four-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default.four-card {
        height: 330px;
    }

    .card-component-tiles .card-default.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.four-card {
        height: 330px;
    }

    .card-component-tiles .card-default.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-default.four-card.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-default.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 768px) {
    .card-component-tiles .card-default.three-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.three-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default.three-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.three-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default.three-card {
        width: 446px;
        height: 330px;
    }

    .card-component-tiles .card-default.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.three-card {
        width: 446px;
        height: 330px;
    }

    .card-component-tiles .card-default.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-default.three-card.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-default.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 768px) {
    .card-component-tiles .card-default.two-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.two-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default.two-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.two-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default.two-card {
        width: 540px;
        height: 500px;
    }

    .card-component-tiles .card-default.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.two-card {
        width: 540px;
        height: 500px;
    }

    .card-component-tiles .card-default.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-default.two-card.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-default.two-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.two-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default.two-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.two-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default.two-card.card-half {
        height: 250px;
    }

    .card-component-tiles .card-default.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.two-card.card-half {
        height: 250px;
    }

    .card-component-tiles .card-default.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-default.one-card {
    margin: auto;
}

@media (min-width: 768px) {
    .card-component-tiles .card-default.one-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.one-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default.one-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.one-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-default.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default.one-card {
        width: 390px;
        height: 500px;
    }

    .card-component-tiles .card-default.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.one-card {
        width: 390px;
        height: 500px;
    }

    .card-component-tiles .card-default.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-default.one-card.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-default.one-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.one-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default.one-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.one-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-default.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default.one-card.card-half {
        height: 250px;
    }

    .card-component-tiles .card-default.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-default.one-card.card-half {
        height: 250px;
    }

    .card-component-tiles .card-default.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-default .icon {
    color: rgb(255, 255, 255);
    font-size: 60px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-default .icon {
        font-size: 35px;
    }

    .card-component-tiles .card-default .icon {
        font-size: 35px;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default .icon {
        font-size: 43px;
    }

    .card-component-tiles .card-default .icon {
        font-size: 43px;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default .icon {
        font-size: 60px;
    }

    .card-component-tiles .card-default .icon {
        font-size: 60px;
    }
}

.card-component-tiles .card-default .hidden {
    display: none;
}

.card-component-tiles .card-default .card-text {
    position: absolute;
    bottom: 16px;
    left: 24px;
    right: 24px;
}

.card-component-tiles .card-default .card-text.focus {
    position: initial;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
}

.card-component-tiles .card-default .card-text .focus-icon {
    color: rgb(255, 255, 255);
    font-size: 60px;
    line-height: 60px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-default .card-text .focus-icon {
        font-size: 35px;
        line-height: 35px;
    }

    .card-component-tiles .card-default .card-text .focus-icon {
        font-size: 35px;
        line-height: 35px;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-default .card-text .focus-icon {
        font-size: 43px;
        line-height: 43px;
    }

    .card-component-tiles .card-default .card-text .focus-icon {
        font-size: 43px;
        line-height: 43px;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-default .card-text .focus-icon {
        font-size: 60px;
        line-height: 60px;
    }

    .card-component-tiles .card-default .card-text .focus-icon {
        font-size: 60px;
        line-height: 60px;
    }
}

.card-component-tiles .card-default .card-text .title {
    font-family: Bogle;
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    padding-bottom: 5px;
}

.card-component-tiles .card-default .card-text .description {
    color: rgb(255, 255, 255);
    font-family: Bogle;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
}

.card-component-tiles .card-new {
    margin: 10px 0px;
    position: relative;
    display: flex;
    width: 376px;
    height: 293px;
}

.card-component-tiles .card-new.img-wrapper {
    background-size: cover;
    background-position: 50% 50%;
}

.card-component-tiles .card-new .link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
}

@media (min-width: 700px) {
    .card-component-tiles .card-new {
        margin: 10px;
    }

    .card-component-tiles .card-new {
        margin: 10px;
    }
}

@media (max-width: 1030px) {
    .card-component-tiles .card-new:first-child.four-card {
        margin-bottom: 10px;
    }

    .card-component-tiles .card-new:first-child.three-card {
        margin-bottom: 10px;
    }

    .card-component-tiles .card-new:first-child.four-card {
        margin-bottom: 10px;
    }

    .card-component-tiles .card-new:first-child.three-card {
        margin-bottom: 10px;
    }
}

@media (min-width: 768px) {
    .card-component-tiles .card-new {
        margin: 0px 10px;
    }

    .card-component-tiles .card-new:first-child {
        margin-left: 0px;
    }

    .card-component-tiles .card-new:last-child {
        margin-right: 0px;
    }

    .card-component-tiles .card-new {
        margin: 0px 10px;
    }

    .card-component-tiles .card-new:first-child {
        margin-left: 0px;
    }

    .card-component-tiles .card-new:last-child {
        margin-right: 0px;
    }
}

@media (min-width: 768px) {
    .card-component-tiles .card-new.four-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.four-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new.four-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.four-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new.four-card {
        height: 330px;
    }

    .card-component-tiles .card-new.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.four-card {
        height: 330px;
    }

    .card-component-tiles .card-new.four-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-new.four-card.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-new.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.four-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.four-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 768px) {
    .card-component-tiles .card-new.three-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.three-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new.three-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.three-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new.three-card {
        width: 446px;
        height: 330px;
    }

    .card-component-tiles .card-new.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.three-card {
        width: 446px;
        height: 330px;
    }

    .card-component-tiles .card-new.three-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-new.three-card.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-new.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.three-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.three-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 768px) {
    .card-component-tiles .card-new.two-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.two-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new.two-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.two-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new.two-card {
        width: 390px;
        height: 500px;
    }

    .card-component-tiles .card-new.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.two-card {
        width: 390px;
        height: 500px;
    }

    .card-component-tiles .card-new.two-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-new.two-card.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-new.two-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.two-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new.two-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.two-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new.two-card.card-half {
        height: 250px;
    }

    .card-component-tiles .card-new.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.two-card.card-half {
        height: 250px;
    }

    .card-component-tiles .card-new.two-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-new.one-card {
    margin: auto;
}

@media (min-width: 768px) {
    .card-component-tiles .card-new.one-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.one-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new.one-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.one-card {
        height: 330px;
        width: 330px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .card-component-tiles .card-new.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new.one-card {
        width: 390px;
        height: 500px;
    }

    .card-component-tiles .card-new.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.one-card {
        width: 390px;
        height: 500px;
    }

    .card-component-tiles .card-new.one-card.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-new.one-card.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-new.one-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.one-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new.one-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.one-card.card-half {
        height: 165px;
    }

    .card-component-tiles .card-new.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new.one-card.card-half {
        height: 250px;
    }

    .card-component-tiles .card-new.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }

    .card-component-tiles .card-new.one-card.card-half {
        height: 250px;
    }

    .card-component-tiles .card-new.one-card.card-half.hover-state:hover {
        cursor: pointer;
        transform: scale(1.08);
        z-index: 1;
    }
}

.card-component-tiles .card-new .icon {
    color: rgb(255, 255, 255);
    font-size: 80px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-new .icon {
        font-size: 35px;
    }

    .card-component-tiles .card-new .icon {
        font-size: 35px;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new .icon {
        font-size: 43px;
    }

    .card-component-tiles .card-new .icon {
        font-size: 43px;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new .icon {
        font-size: 80px;
    }

    .card-component-tiles .card-new .icon {
        font-size: 80px;
    }
}

.card-component-tiles .card-new .hidden {
    display: none;
}

.card-component-tiles .card-new .card-text {
    position: absolute;
    bottom: 16px;
    left: 20px;
}

.card-component-tiles .card-new .card-text.focus {
    position: initial;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
}

.card-component-tiles .card-new .card-text .focus-icon {
    color: rgb(255, 255, 255);
    font-size: 60px;
}

@media (min-width: 768px) {
    .card-component-tiles .card-new .card-text .focus-icon {
        font-size: 35px;
    }

    .card-component-tiles .card-new .card-text .focus-icon {
        font-size: 35px;
    }
}

@media (min-width: 980px) {
    .card-component-tiles .card-new .card-text .focus-icon {
        font-size: 43px;
    }

    .card-component-tiles .card-new .card-text .focus-icon {
        font-size: 43px;
    }
}

@media (min-width: 1200px) {
    .card-component-tiles .card-new .card-text .focus-icon {
        font-size: 60px;
    }

    .card-component-tiles .card-new .card-text .focus-icon {
        font-size: 60px;
    }
}

.card-component-tiles .card-new .card-text .title {
    font-family: Bogle;
    color: rgb(255, 255, 255);
    font-size: 24px;
    line-height: 1;
    font-weight: 400;
    margin: 0px;
}

.card-component-tiles .card-new .card-text .description {
    font-family: Bogle;
    color: rgb(255, 255, 255);
    font-size: 16px;
}

.card-component-tiles .darken-default-image {
    position: absolute;
    color: rgb(0, 0, 0);
    inset: 0px;
    border-radius: 6px;
    margin: auto;
    opacity: 0.3;
}

.card-component-tiles .darken-default-image.opacity-0 {
    background: rgb(0, 0, 0);
    opacity: 0;
}

.card-component-tiles .darken-default-image.opacity-15 {
    background: rgb(0, 0, 0);
    opacity: 0.15 !important;
}

.card-component-tiles .darken-default-image.opacity-30 {
    background: rgb(0, 0, 0);
    opacity: 0.3 !important;
}

.card-component-tiles .darken-default-image.opacity-45 {
    background: rgb(0, 0, 0);
    opacity: 0.45 !important;
}

.card-component-tiles .darken-image {
    position: absolute;
    color: rgb(0, 0, 0);
    inset: 0px;
    margin: auto;
    opacity: 0.3;
}

.card-component-tiles .darken-image.opacity-0 {
    background: rgb(0, 0, 0);
    opacity: 0;
}

.card-component-tiles .darken-image.opacity-15 {
    background: rgb(0, 0, 0);
    opacity: 0.15;
}

.card-component-tiles .darken-image.opacity-30 {
    background: rgb(0, 0, 0);
    opacity: 0.3;
}

.card-component-tiles .darken-image.opacity-45 {
    background: rgb(0, 0, 0);
    opacity: 0.45;
}

@media (min-width: 768px) {
    .columns-new-component.col-new-six .card-component-tiles .card, .columns-new-component.col-new-six .card-component-tiles .card-new, .columns-new-component.col-new-six .card-component-tiles .card-default, .columns-new-component.col-new-five .card-component-tiles .card, .columns-new-component.col-new-five .card-component-tiles .card-new, .columns-new-component.col-new-five .card-component-tiles .card-default, .columns-new-component.col-new-four .card-component-tiles .card, .columns-new-component.col-new-four .card-component-tiles .card-new, .columns-new-component.col-new-four .card-component-tiles .card-default {
        height: 330px;
    }

    .columns-new-component.col-new-six .card-component-tiles .card, .columns-new-component.col-new-six .card-component-tiles .card-new, .columns-new-component.col-new-six .card-component-tiles .card-default, .columns-new-component.col-new-five .card-component-tiles .card, .columns-new-component.col-new-five .card-component-tiles .card-new, .columns-new-component.col-new-five .card-component-tiles .card-default, .columns-new-component.col-new-four .card-component-tiles .card, .columns-new-component.col-new-four .card-component-tiles .card-new, .columns-new-component.col-new-four .card-component-tiles .card-default {
        height: 330px;
    }
}

@media (min-width: 980px) {
    .columns-new-component.col-new-six .card-component-tiles .card, .columns-new-component.col-new-six .card-component-tiles .card-new, .columns-new-component.col-new-six .card-component-tiles .card-default, .columns-new-component.col-new-five .card-component-tiles .card, .columns-new-component.col-new-five .card-component-tiles .card-new, .columns-new-component.col-new-five .card-component-tiles .card-default, .columns-new-component.col-new-four .card-component-tiles .card, .columns-new-component.col-new-four .card-component-tiles .card-new, .columns-new-component.col-new-four .card-component-tiles .card-default {
        height: 330px;
    }

    .columns-new-component.col-new-six .card-component-tiles .card, .columns-new-component.col-new-six .card-component-tiles .card-new, .columns-new-component.col-new-six .card-component-tiles .card-default, .columns-new-component.col-new-five .card-component-tiles .card, .columns-new-component.col-new-five .card-component-tiles .card-new, .columns-new-component.col-new-five .card-component-tiles .card-default, .columns-new-component.col-new-four .card-component-tiles .card, .columns-new-component.col-new-four .card-component-tiles .card-new, .columns-new-component.col-new-four .card-component-tiles .card-default {
        height: 330px;
    }
}

@media (min-width: 1200px) {
    .columns-new-component.col-new-six .card-component-tiles .card, .columns-new-component.col-new-six .card-component-tiles .card-new, .columns-new-component.col-new-six .card-component-tiles .card-default, .columns-new-component.col-new-five .card-component-tiles .card, .columns-new-component.col-new-five .card-component-tiles .card-new, .columns-new-component.col-new-five .card-component-tiles .card-default, .columns-new-component.col-new-four .card-component-tiles .card, .columns-new-component.col-new-four .card-component-tiles .card-new, .columns-new-component.col-new-four .card-component-tiles .card-default {
        height: 330px;
    }

    .columns-new-component.col-new-six .card-component-tiles .card, .columns-new-component.col-new-six .card-component-tiles .card-new, .columns-new-component.col-new-six .card-component-tiles .card-default, .columns-new-component.col-new-five .card-component-tiles .card, .columns-new-component.col-new-five .card-component-tiles .card-new, .columns-new-component.col-new-five .card-component-tiles .card-default, .columns-new-component.col-new-four .card-component-tiles .card, .columns-new-component.col-new-four .card-component-tiles .card-new, .columns-new-component.col-new-four .card-component-tiles .card-default {
        height: 330px;
    }
}

.columns-new-component.col-new-six .card-component-tiles .card.card-half, .columns-new-component.col-new-six .card-component-tiles .card-new.card-half, .columns-new-component.col-new-six .card-component-tiles .card-default.card-half, .columns-new-component.col-new-five .card-component-tiles .card.card-half, .columns-new-component.col-new-five .card-component-tiles .card-new.card-half, .columns-new-component.col-new-five .card-component-tiles .card-default.card-half, .columns-new-component.col-new-four .card-component-tiles .card.card-half, .columns-new-component.col-new-four .card-component-tiles .card-new.card-half, .columns-new-component.col-new-four .card-component-tiles .card-default.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .columns-new-component.col-new-six .card-component-tiles .card.card-half, .columns-new-component.col-new-six .card-component-tiles .card-new.card-half, .columns-new-component.col-new-six .card-component-tiles .card-default.card-half, .columns-new-component.col-new-five .card-component-tiles .card.card-half, .columns-new-component.col-new-five .card-component-tiles .card-new.card-half, .columns-new-component.col-new-five .card-component-tiles .card-default.card-half, .columns-new-component.col-new-four .card-component-tiles .card.card-half, .columns-new-component.col-new-four .card-component-tiles .card-new.card-half, .columns-new-component.col-new-four .card-component-tiles .card-default.card-half {
        height: 165px;
    }

    .columns-new-component.col-new-six .card-component-tiles .card.card-half, .columns-new-component.col-new-six .card-component-tiles .card-new.card-half, .columns-new-component.col-new-six .card-component-tiles .card-default.card-half, .columns-new-component.col-new-five .card-component-tiles .card.card-half, .columns-new-component.col-new-five .card-component-tiles .card-new.card-half, .columns-new-component.col-new-five .card-component-tiles .card-default.card-half, .columns-new-component.col-new-four .card-component-tiles .card.card-half, .columns-new-component.col-new-four .card-component-tiles .card-new.card-half, .columns-new-component.col-new-four .card-component-tiles .card-default.card-half {
        height: 165px;
    }
}

@media (min-width: 980px) {
    .columns-new-component.col-new-six .card-component-tiles .card.card-half, .columns-new-component.col-new-six .card-component-tiles .card-new.card-half, .columns-new-component.col-new-six .card-component-tiles .card-default.card-half, .columns-new-component.col-new-five .card-component-tiles .card.card-half, .columns-new-component.col-new-five .card-component-tiles .card-new.card-half, .columns-new-component.col-new-five .card-component-tiles .card-default.card-half, .columns-new-component.col-new-four .card-component-tiles .card.card-half, .columns-new-component.col-new-four .card-component-tiles .card-new.card-half, .columns-new-component.col-new-four .card-component-tiles .card-default.card-half {
        height: 165px;
    }

    .columns-new-component.col-new-six .card-component-tiles .card.card-half, .columns-new-component.col-new-six .card-component-tiles .card-new.card-half, .columns-new-component.col-new-six .card-component-tiles .card-default.card-half, .columns-new-component.col-new-five .card-component-tiles .card.card-half, .columns-new-component.col-new-five .card-component-tiles .card-new.card-half, .columns-new-component.col-new-five .card-component-tiles .card-default.card-half, .columns-new-component.col-new-four .card-component-tiles .card.card-half, .columns-new-component.col-new-four .card-component-tiles .card-new.card-half, .columns-new-component.col-new-four .card-component-tiles .card-default.card-half {
        height: 165px;
    }
}

@media (min-width: 1200px) {
    .columns-new-component.col-new-six .card-component-tiles .card.card-half, .columns-new-component.col-new-six .card-component-tiles .card-new.card-half, .columns-new-component.col-new-six .card-component-tiles .card-default.card-half, .columns-new-component.col-new-five .card-component-tiles .card.card-half, .columns-new-component.col-new-five .card-component-tiles .card-new.card-half, .columns-new-component.col-new-five .card-component-tiles .card-default.card-half, .columns-new-component.col-new-four .card-component-tiles .card.card-half, .columns-new-component.col-new-four .card-component-tiles .card-new.card-half, .columns-new-component.col-new-four .card-component-tiles .card-default.card-half {
        height: 165px;
    }

    .columns-new-component.col-new-six .card-component-tiles .card.card-half, .columns-new-component.col-new-six .card-component-tiles .card-new.card-half, .columns-new-component.col-new-six .card-component-tiles .card-default.card-half, .columns-new-component.col-new-five .card-component-tiles .card.card-half, .columns-new-component.col-new-five .card-component-tiles .card-new.card-half, .columns-new-component.col-new-five .card-component-tiles .card-default.card-half, .columns-new-component.col-new-four .card-component-tiles .card.card-half, .columns-new-component.col-new-four .card-component-tiles .card-new.card-half, .columns-new-component.col-new-four .card-component-tiles .card-default.card-half {
        height: 165px;
    }
}

@media (min-width: 768px) {
    .columns-new-component.col-new-three .card-component-tiles .card, .columns-new-component.col-new-three .card-component-tiles .card-new, .columns-new-component.col-new-three .card-component-tiles .card-default {
        height: 330px;
    }

    .columns-new-component.col-new-three .card-component-tiles .card, .columns-new-component.col-new-three .card-component-tiles .card-new, .columns-new-component.col-new-three .card-component-tiles .card-default {
        height: 330px;
    }
}

@media (min-width: 980px) {
    .columns-new-component.col-new-three .card-component-tiles .card, .columns-new-component.col-new-three .card-component-tiles .card-new, .columns-new-component.col-new-three .card-component-tiles .card-default {
        height: 330px;
    }

    .columns-new-component.col-new-three .card-component-tiles .card, .columns-new-component.col-new-three .card-component-tiles .card-new, .columns-new-component.col-new-three .card-component-tiles .card-default {
        height: 330px;
    }
}

@media (min-width: 1200px) {
    .columns-new-component.col-new-three .card-component-tiles .card, .columns-new-component.col-new-three .card-component-tiles .card-new, .columns-new-component.col-new-three .card-component-tiles .card-default {
        height: 330px;
    }

    .columns-new-component.col-new-three .card-component-tiles .card, .columns-new-component.col-new-three .card-component-tiles .card-new, .columns-new-component.col-new-three .card-component-tiles .card-default {
        height: 330px;
    }
}

.columns-new-component.col-new-three .card-component-tiles .card.card-half, .columns-new-component.col-new-three .card-component-tiles .card-new.card-half, .columns-new-component.col-new-three .card-component-tiles .card-default.card-half {
    height: 165px;
}

@media (min-width: 768px) {
    .columns-new-component.col-new-three .card-component-tiles .card.card-half, .columns-new-component.col-new-three .card-component-tiles .card-new.card-half, .columns-new-component.col-new-three .card-component-tiles .card-default.card-half {
        height: 165px;
    }

    .columns-new-component.col-new-three .card-component-tiles .card.card-half, .columns-new-component.col-new-three .card-component-tiles .card-new.card-half, .columns-new-component.col-new-three .card-component-tiles .card-default.card-half {
        height: 165px;
    }
}

@media (min-width: 980px) {
    .columns-new-component.col-new-three .card-component-tiles .card.card-half, .columns-new-component.col-new-three .card-component-tiles .card-new.card-half, .columns-new-component.col-new-three .card-component-tiles .card-default.card-half {
        height: 165px;
    }

    .columns-new-component.col-new-three .card-component-tiles .card.card-half, .columns-new-component.col-new-three .card-component-tiles .card-new.card-half, .columns-new-component.col-new-three .card-component-tiles .card-default.card-half {
        height: 165px;
    }
}

@media (min-width: 1200px) {
    .columns-new-component.col-new-three .card-component-tiles .card.card-half, .columns-new-component.col-new-three .card-component-tiles .card-new.card-half, .columns-new-component.col-new-three .card-component-tiles .card-default.card-half {
        height: 165px;
    }

    .columns-new-component.col-new-three .card-component-tiles .card.card-half, .columns-new-component.col-new-three .card-component-tiles .card-new.card-half, .columns-new-component.col-new-three .card-component-tiles .card-default.card-half {
        height: 165px;
    }
}

@media (min-width: 768px) {
    .columns-new-component.col-new-two .card-component-tiles .card, .columns-new-component.col-new-two .card-component-tiles .card-new, .columns-new-component.col-new-two .card-component-tiles .card-default {
        height: 350px;
    }

    .columns-new-component.col-new-two .card-component-tiles .card, .columns-new-component.col-new-two .card-component-tiles .card-new, .columns-new-component.col-new-two .card-component-tiles .card-default {
        height: 350px;
    }
}

@media (min-width: 980px) {
    .columns-new-component.col-new-two .card-component-tiles .card, .columns-new-component.col-new-two .card-component-tiles .card-new, .columns-new-component.col-new-two .card-component-tiles .card-default {
        height: 400px;
    }

    .columns-new-component.col-new-two .card-component-tiles .card, .columns-new-component.col-new-two .card-component-tiles .card-new, .columns-new-component.col-new-two .card-component-tiles .card-default {
        height: 400px;
    }
}

@media (min-width: 1200px) {
    .columns-new-component.col-new-two .card-component-tiles .card, .columns-new-component.col-new-two .card-component-tiles .card-new, .columns-new-component.col-new-two .card-component-tiles .card-default {
        height: 500px;
    }

    .columns-new-component.col-new-two .card-component-tiles .card, .columns-new-component.col-new-two .card-component-tiles .card-new, .columns-new-component.col-new-two .card-component-tiles .card-default {
        height: 500px;
    }
}

.columns-new-component.col-new-two .card-component-tiles .card.card-half, .columns-new-component.col-new-two .card-component-tiles .card-new.card-half, .columns-new-component.col-new-two .card-component-tiles .card-default.card-half {
    height: 175px;
}

@media (min-width: 768px) {
    .columns-new-component.col-new-two .card-component-tiles .card.card-half, .columns-new-component.col-new-two .card-component-tiles .card-new.card-half, .columns-new-component.col-new-two .card-component-tiles .card-default.card-half {
        height: 175px;
    }

    .columns-new-component.col-new-two .card-component-tiles .card.card-half, .columns-new-component.col-new-two .card-component-tiles .card-new.card-half, .columns-new-component.col-new-two .card-component-tiles .card-default.card-half {
        height: 175px;
    }
}

@media (min-width: 980px) {
    .columns-new-component.col-new-two .card-component-tiles .card.card-half, .columns-new-component.col-new-two .card-component-tiles .card-new.card-half, .columns-new-component.col-new-two .card-component-tiles .card-default.card-half {
        height: 200px;
    }

    .columns-new-component.col-new-two .card-component-tiles .card.card-half, .columns-new-component.col-new-two .card-component-tiles .card-new.card-half, .columns-new-component.col-new-two .card-component-tiles .card-default.card-half {
        height: 200px;
    }
}

@media (min-width: 1200px) {
    .columns-new-component.col-new-two .card-component-tiles .card.card-half, .columns-new-component.col-new-two .card-component-tiles .card-new.card-half, .columns-new-component.col-new-two .card-component-tiles .card-default.card-half {
        height: 250px;
    }

    .columns-new-component.col-new-two .card-component-tiles .card.card-half, .columns-new-component.col-new-two .card-component-tiles .card-new.card-half, .columns-new-component.col-new-two .card-component-tiles .card-default.card-half {
        height: 250px;
    }
}

.columns-new-component.col-new-one .card-component-tiles .card, .columns-new-component.col-new-one .card-component-tiles .card-new, .columns-new-component.col-new-one .card-component-tiles .card-default {
    margin: auto;
}

@media (min-width: 768px) {
    .columns-new-component.col-new-one .card-component-tiles .card, .columns-new-component.col-new-one .card-component-tiles .card-new, .columns-new-component.col-new-one .card-component-tiles .card-default {
        height: 350px;
    }

    .columns-new-component.col-new-one .card-component-tiles .card, .columns-new-component.col-new-one .card-component-tiles .card-new, .columns-new-component.col-new-one .card-component-tiles .card-default {
        height: 350px;
    }
}

@media (min-width: 980px) {
    .columns-new-component.col-new-one .card-component-tiles .card, .columns-new-component.col-new-one .card-component-tiles .card-new, .columns-new-component.col-new-one .card-component-tiles .card-default {
        height: 400px;
    }

    .columns-new-component.col-new-one .card-component-tiles .card, .columns-new-component.col-new-one .card-component-tiles .card-new, .columns-new-component.col-new-one .card-component-tiles .card-default {
        height: 400px;
    }
}

@media (min-width: 1200px) {
    .columns-new-component.col-new-one .card-component-tiles .card, .columns-new-component.col-new-one .card-component-tiles .card-new, .columns-new-component.col-new-one .card-component-tiles .card-default {
        height: 500px;
    }

    .columns-new-component.col-new-one .card-component-tiles .card, .columns-new-component.col-new-one .card-component-tiles .card-new, .columns-new-component.col-new-one .card-component-tiles .card-default {
        height: 500px;
    }
}

.columns-new-component.col-new-one .card-component-tiles .card.card-half, .columns-new-component.col-new-one .card-component-tiles .card-new.card-half, .columns-new-component.col-new-one .card-component-tiles .card-default.card-half {
    height: 175px;
}

@media (min-width: 768px) {
    .columns-new-component.col-new-one .card-component-tiles .card.card-half, .columns-new-component.col-new-one .card-component-tiles .card-new.card-half, .columns-new-component.col-new-one .card-component-tiles .card-default.card-half {
        height: 175px;
    }

    .columns-new-component.col-new-one .card-component-tiles .card.card-half, .columns-new-component.col-new-one .card-component-tiles .card-new.card-half, .columns-new-component.col-new-one .card-component-tiles .card-default.card-half {
        height: 175px;
    }
}

@media (min-width: 980px) {
    .columns-new-component.col-new-one .card-component-tiles .card.card-half, .columns-new-component.col-new-one .card-component-tiles .card-new.card-half, .columns-new-component.col-new-one .card-component-tiles .card-default.card-half {
        height: 200px;
    }

    .columns-new-component.col-new-one .card-component-tiles .card.card-half, .columns-new-component.col-new-one .card-component-tiles .card-new.card-half, .columns-new-component.col-new-one .card-component-tiles .card-default.card-half {
        height: 200px;
    }
}

@media (min-width: 1200px) {
    .columns-new-component.col-new-one .card-component-tiles .card.card-half, .columns-new-component.col-new-one .card-component-tiles .card-new.card-half, .columns-new-component.col-new-one .card-component-tiles .card-default.card-half {
        height: 250px;
    }

    .columns-new-component.col-new-one .card-component-tiles .card.card-half, .columns-new-component.col-new-one .card-component-tiles .card-new.card-half, .columns-new-component.col-new-one .card-component-tiles .card-default.card-half {
        height: 250px;
    }
}

.cards-cion {
    width: 80px;
    line-height: 1;
    font-size: 80px;
}

.cards-cion-highlight {
    width: 80px;
    line-height: 1;
    font-size: 80px;
}

.font-fix {
    font-style: normal;
}

.card-cta-new {
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: rgb(255, 255, 255);
}

.new-cards-style:hover .link-present {
    opacity: 0.5;
}

.new-cards-style:hover .link-present.stop-opacity {
    opacity: 0;
}

.new-cards-style .left {
    text-align: left;
}

.new-cards-style .right {
    text-align: right;
}

.new-cards-style .center {
    text-align: center;
}

.new-cards-style ul {
    line-height: 1.3em;
    display: grid;
    list-style-position: inside;
}

.new-cards-style ol {
    line-height: 1.3em;
    display: grid;
    list-style-position: inside;
}

.highlight-image-new {
    font-family: Bogle;
    color: rgb(255, 255, 255);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.15s ease 0s;
    margin: 0px;
    border-radius: 0px !important;
    box-shadow: none !important;
}

.highlight-image-new .left {
    justify-content: left !important;
    text-align: left !important;
}

.highlight-image-new .right {
    justify-content: right !important;
    text-align: right !important;
}

.highlight-image-new .right.card-cta-2 {
    right: 24px;
}

.highlight-image-new .center {
    justify-content: center !important;
    text-align: center !important;
}

.highlight-image-new .center.card-cta-2 {
    width: 100%;
    margin-left: 0px;
}

.highlight-image-new .highlight-border {
    border-radius: 8px;
    overflow: hidden;
}

.highlight-image-new .opacity-set {
    opacity: 0.3;
}

.highlight-image-new .opacity-set.cIcon {
    opacity: 1;
}

.highlight-image-new .card-half .card-desc-2 {
    display: none;
}

.highlight-image-new ul {
    line-height: 1.3em;
    display: grid;
    list-style-position: inside;
}

.highlight-image-new ol {
    line-height: 1.3em;
    display: grid;
    list-style-position: inside;
}

.highlight-image-new .card-icon {
    font-size: 60px;
}

@media (min-width: 768px) {
    .highlight-image-new .card-icon {
        font-size: 35px;
    }

    .highlight-image-new .card-icon {
        font-size: 35px;
    }
}

@media (min-width: 980px) {
    .highlight-image-new .card-icon {
        font-size: 43px;
    }

    .highlight-image-new .card-icon {
        font-size: 43px;
    }
}

@media (min-width: 1200px) {
    .highlight-image-new .card-icon {
        font-size: 60px;
    }

    .highlight-image-new .card-icon {
        font-size: 60px;
    }
}

.highlight-image-new .card-new:hover ul {
    line-height: 1.3em;
    list-style-position: inside;
}

.highlight-image-new .card-new:hover .hide-on-hover {
    display: none;
}

.highlight-image-new .card-new:hover .card-highlight-title {
    color: rgb(255, 255, 255);
    padding-top: 16px;
}

.highlight-image-new .card-new:hover .card-desc-2 {
    white-space: break-spaces;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: rgb(255, 255, 255);
    padding-left: 24px;
}

.highlight-image-new .card-new:hover .card-desc-2.right {
    display: grid;
}

.highlight-image-new .card-new:hover .card-icon {
    color: rgb(255, 255, 255);
}

.highlight-image-new .card-new:hover .card-cta-2 {
    display: flex;
    color: rgb(255, 255, 255);
}

.highlight-image-new .card-new:hover .cards-cion {
    display: block;
}

.highlight-image-new .card-new:hover .bg-yellow {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(255, 252, 191, 0.8), rgba(255, 242, 0, 0.8)), linear-gradient(to left, rgb(255, 252, 191), rgb(255, 242, 0));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

.highlight-image-new .card-new:hover .bg-yellow .card-desc-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-yellow .card-cta-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-yellow .card-icon {
    color: rgb(0, 0, 0);
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-yellow {
        background-color: rgba(255, 242, 0, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-yellow {
        background-color: rgba(255, 242, 0, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .gt-global-green {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(193, 252, 222, 0.8), rgba(16, 242, 123, 0.8)), linear-gradient(to left, rgb(193, 252, 222), rgb(6, 242, 123));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .gt-global-green {
        background-color: rgba(6, 242, 123, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .gt-global-green {
        background-color: rgba(6, 242, 123, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .gt-granite-grey {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(215, 215, 216, 0.8), rgba(96, 94, 99, 0.8)), linear-gradient(to left, rgb(215, 215, 216), rgb(96, 94, 99));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .gt-granite-grey {
        background-color: rgba(96, 94, 99, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .gt-granite-grey {
        background-color: rgba(96, 94, 99, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .gt-grey {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(237, 238, 241, 0.8), rgba(185, 187, 197, 0.8)), linear-gradient(to left, rgb(237, 238, 241), rgb(185, 187, 197));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .gt-grey {
        background-color: rgba(185, 187, 197, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .gt-grey {
        background-color: rgba(185, 187, 197, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .gt-blue-ink {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(192, 199, 208, 0.8), rgba(4, 31, 65, 0.8)), linear-gradient(to left, rgb(192, 199, 208), rgb(4, 31, 65));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .gt-blue-ink {
        background-color: rgba(4, 31, 65, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .gt-blue-ink {
        background-color: rgba(4, 31, 65, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-purple {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(208, 200, 225, 0.8), rgba(67, 33, 136, 0.8)), linear-gradient(to left, rgb(208, 200, 225), rgb(67, 33, 136));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-purple {
        background-color: rgba(67, 33, 136, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-purple {
        background-color: rgba(67, 33, 136, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-pink {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(250, 196, 227, 0.8), rgba(235, 20, 141, 0.8)), linear-gradient(to left, rgb(250, 196, 227), rgb(235, 20, 141));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-pink {
        background-color: rgba(235, 20, 141, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-pink {
        background-color: rgba(235, 20, 141, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-dark-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(193, 199, 202, 0.8), rgba(8, 31, 44, 0.8)), linear-gradient(to left, rgb(193, 199, 202), rgb(8, 31, 44));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-dark-blue {
        background-color: rgba(8, 31, 44, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-dark-blue {
        background-color: rgba(8, 31, 44, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-medium-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(191, 219, 243, 0.8), rgba(0, 112, 206, 0.8)), linear-gradient(to left, rgb(191, 219, 243), rgb(0, 112, 206));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-medium-blue {
        background-color: rgba(0, 112, 206, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-medium-blue {
        background-color: rgba(0, 112, 206, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-light-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(221, 237, 249, 0.8), rgba(120, 185, 231, 0.8)), linear-gradient(to left, rgb(221, 237, 249), rgb(120, 185, 231));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-light-blue {
        background-color: rgba(120, 185, 231, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-light-blue {
        background-color: rgba(120, 185, 231, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-yellow {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(255, 240, 199, 0.8), rgba(255, 194, 32, 0.8)), linear-gradient(to left, rgb(255, 240, 199), rgb(255, 194, 32));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-yellow {
        background-color: rgba(255, 194, 32, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-yellow {
        background-color: rgba(255, 194, 32, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-green {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(221, 239, 208, 0.8), rgba(118, 192, 67, 0.8)), linear-gradient(to left, rgb(221, 239, 208), rgb(118, 192, 67));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-green {
        background-color: rgba(118, 192, 67, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-green {
        background-color: rgba(118, 192, 67, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-red {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(251, 206, 202, 0.8), rgba(238, 59, 42, 0.8)), linear-gradient(to left, rgb(251, 206, 202), rgb(238, 59, 42));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-red {
        background-color: rgba(238, 59, 42, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-red {
        background-color: rgba(238, 59, 42, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .wmi-orange {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(252, 220, 200, 0.8), rgba(244, 117, 33, 0.8)), linear-gradient(to left, rgb(252, 220, 200), rgb(244, 117, 33));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .wmi-orange {
        background-color: rgba(244, 117, 33, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .wmi-orange {
        background-color: rgba(244, 117, 33, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-green {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(215, 236, 209, 0.8), rgba(95, 180, 70, 0.8)), linear-gradient(to left, rgb(215, 236, 209), rgb(95, 180, 70));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-green {
        background-color: rgba(95, 180, 70, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-green {
        background-color: rgba(95, 180, 70, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-light-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(120, 185, 231, 0.8), rgba(120, 185, 231, 0.8)), linear-gradient(to left, rgb(241, 248, 252), rgb(120, 185, 231));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-light-blue {
        background-color: rgba(120, 185, 231, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-light-blue {
        background-color: rgba(120, 185, 231, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-red {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(247, 198, 200, 0.8), rgba(222, 28, 36, 0.8)), linear-gradient(to left, rgb(247, 198, 200), rgb(222, 28, 36));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-red {
        background-color: rgba(222, 28, 36, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-red {
        background-color: rgba(222, 28, 36, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-orange {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(254, 216, 191, 0.8), rgba(250, 100, 0, 0.8)), linear-gradient(to left, rgb(254, 216, 191), rgb(250, 100, 0));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-orange {
        background-color: rgba(250, 100, 0, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-orange {
        background-color: rgba(250, 100, 0, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-teal {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(62, 197, 230, 0.8), rgba(62, 197, 230, 0.8)), linear-gradient(to left, rgb(235, 249, 252), rgb(62, 197, 230));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-teal {
        background-color: rgba(62, 197, 230, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-teal {
        background-color: rgba(62, 197, 230, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-labs {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(48, 41, 81, 0.8), rgba(48, 41, 81, 0.8)), linear-gradient(to left, rgb(234, 233, 237), rgb(48, 41, 81));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-labs {
        background-color: rgba(48, 41, 81, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-labs {
        background-color: rgba(48, 41, 81, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-black, .highlight-image-new .card-new:hover .bg-allswell-black, .highlight-image-new .card-new:hover .bg-barenecessities-black, .highlight-image-new .card-new:hover .bg-parcel-black {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), linear-gradient(to left, rgb(229, 229, 229), rgb(0, 0, 0));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-black, .highlight-image-new .card-new:hover .bg-allswell-black, .highlight-image-new .card-new:hover .bg-barenecessities-black, .highlight-image-new .card-new:hover .bg-parcel-black {
        background-color: rgba(0, 0, 0, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-black, .highlight-image-new .card-new:hover .bg-allswell-black, .highlight-image-new .card-new:hover .bg-barenecessities-black, .highlight-image-new .card-new:hover .bg-parcel-black {
        background-color: rgba(0, 0, 0, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-gray {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(117, 117, 117, 0.8), rgba(117, 117, 117, 0.8)), linear-gradient(to left, rgb(241, 241, 241), rgb(117, 117, 117));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-gray {
        background-color: rgba(117, 117, 117, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-gray {
        background-color: rgba(117, 117, 117, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-light-gray {
    color: rgb(0, 0, 0);
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(242, 242, 242, 0.8), rgba(242, 242, 242, 0.8)), linear-gradient(to left, rgb(253, 253, 253), rgb(242, 242, 242));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

.highlight-image-new .card-new:hover .bg-light-gray .card-highlight-title {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-light-gray .card-desc-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-light-gray .card-cta-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-light-gray .card-icon {
    color: rgb(0, 0, 0);
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-light-gray {
        background-color: rgba(242, 242, 242, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-light-gray {
        background-color: rgba(242, 242, 242, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-transparent {
    color: rgb(0, 0, 0);
    height: 100%;
    border-radius: 8px;
    border: 0px solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.highlight-image-new .card-new:hover .bg-transparent .card-highlight-title {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-transparent .card-desc-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-transparent .card-cta-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-transparent .card-icon {
    color: rgb(0, 0, 0);
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-transparent {
        background-color: transparent;
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-transparent {
        background-color: transparent;
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-white {
    color: rgb(0, 0, 0);
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(to left, rgb(229, 240, 250), rgb(0, 113, 206));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

.highlight-image-new .card-new:hover .bg-white .card-highlight-title {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-white .card-desc-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-white .card-cta-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-white .card-icon {
    color: rgb(0, 0, 0);
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-white {
        background-color: rgba(255, 255, 255, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-white {
        background-color: rgba(255, 255, 255, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-primary-dark-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(0, 53, 142, 0.8), rgba(0, 53, 142, 0.8)), linear-gradient(to left, rgb(229, 234, 243), rgb(0, 53, 142));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-primary-dark-blue {
        background-color: rgba(0, 53, 142, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-primary-dark-blue {
        background-color: rgba(0, 53, 142, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-primary-main-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(0, 103, 160, 0.8), rgba(0, 103, 160, 0.8)), linear-gradient(to left, rgb(229, 239, 245), rgb(0, 103, 160));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-primary-main-blue {
        background-color: rgba(0, 103, 160, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-primary-main-blue {
        background-color: rgba(0, 103, 160, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-primary-light-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(164, 219, 232, 0.8), rgba(164, 219, 232, 0.8)), linear-gradient(to left, rgb(245, 251, 252), rgb(164, 219, 232));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-primary-light-blue {
        background-color: rgba(164, 219, 232, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-primary-light-blue {
        background-color: rgba(164, 219, 232, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-secondary-light-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(0, 175, 215, 0.8), rgba(0, 175, 215, 0.8)), linear-gradient(to left, rgb(229, 247, 251), rgb(0, 175, 215));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-secondary-light-blue {
        background-color: rgba(0, 175, 215, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-secondary-light-blue {
        background-color: rgba(0, 175, 215, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-secondary-dark-gray {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(124, 135, 142, 0.8), rgba(124, 135, 142, 0.8)), linear-gradient(to left, rgb(241, 243, 243), rgb(124, 135, 142));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-secondary-dark-gray {
        background-color: rgba(124, 135, 142, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-secondary-dark-gray {
        background-color: rgba(124, 135, 142, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-secondary-light-gray {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(208, 211, 212, 0.8), rgba(208, 211, 212, 0.8)), linear-gradient(to left, rgb(250, 250, 250), rgb(208, 211, 212));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-secondary-light-gray {
        background-color: rgba(208, 211, 212, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-secondary-light-gray {
        background-color: rgba(208, 211, 212, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-supp-dark-purple {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(81, 70, 137, 0.8), rgba(81, 70, 137, 0.8)), linear-gradient(to left, rgb(237, 236, 243), rgb(81, 70, 137));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-supp-dark-purple {
        background-color: rgba(81, 70, 137, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-supp-dark-purple {
        background-color: rgba(81, 70, 137, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-supp-light-purple {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(144, 99, 205, 0.8), rgba(144, 99, 205, 0.8)), linear-gradient(to left, rgb(243, 239, 250), rgb(144, 99, 205));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-supp-light-purple {
        background-color: rgba(144, 99, 205, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-supp-light-purple {
        background-color: rgba(144, 99, 205, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-supp-green {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(0, 111, 68, 0.8), rgba(0, 111, 68, 0.8)), linear-gradient(to left, rgb(229, 240, 236), rgb(0, 111, 68));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-supp-green {
        background-color: rgba(0, 111, 68, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-supp-green {
        background-color: rgba(0, 111, 68, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-supp-teal {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(71, 215, 172, 0.8), rgba(71, 215, 172, 0.8)), linear-gradient(to left, rgb(236, 251, 246), rgb(71, 215, 172));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-supp-teal {
        background-color: rgba(71, 215, 172, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-supp-teal {
        background-color: rgba(71, 215, 172, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-supp-red {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(250, 70, 22, 0.8), rgba(250, 70, 22, 0.8)), linear-gradient(to left, rgb(254, 236, 231), rgb(250, 70, 22));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-supp-red {
        background-color: rgba(250, 70, 22, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-supp-red {
        background-color: rgba(250, 70, 22, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-supp-orange {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(255, 143, 28, 0.8), rgba(255, 143, 28, 0.8)), linear-gradient(to left, rgb(255, 243, 232), rgb(255, 143, 28));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-supp-orange {
        background-color: rgba(255, 143, 28, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-supp-orange {
        background-color: rgba(255, 143, 28, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-supp-pink {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(222, 27, 115, 0.8), rgba(222, 27, 115, 0.8)), linear-gradient(to left, rgb(251, 232, 241), rgb(222, 27, 115));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-supp-pink {
        background-color: rgba(222, 27, 115, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-supp-pink {
        background-color: rgba(222, 27, 115, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-sams-supp-yellow {
    color: rgb(0, 0, 0);
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(255, 181, 0, 0.8), rgba(255, 181, 0, 0.8)), linear-gradient(to left, rgb(255, 247, 229), rgb(255, 181, 0));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

.highlight-image-new .card-new:hover .bg-sams-supp-yellow .card-highlight-title {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-sams-supp-yellow .card-desc-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-sams-supp-yellow .card-cta-2 {
    color: rgb(0, 0, 0);
}

.highlight-image-new .card-new:hover .bg-sams-supp-yellow .card-icon {
    color: rgb(0, 0, 0);
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-sams-supp-yellow {
        background-color: rgba(255, 181, 0, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-sams-supp-yellow {
        background-color: rgba(255, 181, 0, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-jet-purple {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(79, 0, 153, 0.8), rgba(79, 0, 153, 0.8)), linear-gradient(to left, rgb(237, 229, 244), rgb(79, 0, 153));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-jet-purple {
        background-color: rgba(79, 0, 153, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-jet-purple {
        background-color: rgba(79, 0, 153, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-moosejaw-red {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(169, 52, 57, 0.8), rgba(169, 52, 57, 0.8)), linear-gradient(to left, rgb(246, 234, 235), rgb(169, 52, 57));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-moosejaw-red {
        background-color: rgba(169, 52, 57, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-moosejaw-red {
        background-color: rgba(169, 52, 57, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-modcloth-gray-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(72, 81, 95, 0.8), rgba(72, 81, 95, 0.8)), linear-gradient(to left, rgb(236, 237, 239), rgb(72, 81, 95));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-modcloth-gray-blue {
        background-color: rgba(72, 81, 95, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-modcloth-gray-blue {
        background-color: rgba(72, 81, 95, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-hayneedle-gray-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(69, 84, 95, 0.8), rgba(69, 84, 95, 0.8)), linear-gradient(to left, rgb(236, 237, 239), rgb(69, 84, 95));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-hayneedle-gray-blue {
        background-color: rgba(69, 84, 95, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-hayneedle-gray-blue {
        background-color: rgba(69, 84, 95, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-shoes-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(32, 53, 102, 0.8), rgba(32, 53, 102, 0.8)), linear-gradient(to left, rgb(232, 234, 239), rgb(32, 53, 102));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-shoes-blue {
        background-color: rgba(32, 53, 102, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-shoes-blue {
        background-color: rgba(32, 53, 102, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-bonobos-brown {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(47, 18, 20, 0.8), rgba(47, 18, 20, 0.8)), linear-gradient(to left, rgb(234, 231, 231), rgb(47, 18, 20));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-bonobos-brown {
        background-color: rgba(47, 18, 20, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-bonobos-brown {
        background-color: rgba(47, 18, 20, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-vudu-blue {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(0, 114, 206, 0.8), rgba(0, 114, 206, 0.8)), linear-gradient(to left, rgb(229, 240, 250), rgb(0, 114, 206));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-vudu-blue {
        background-color: rgba(0, 114, 206, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-vudu-blue {
        background-color: rgba(0, 114, 206, 0.9);
        height: 100%;
    }
}

.highlight-image-new .card-new:hover .bg-eloquil-brown {
    height: 100%;
    border-radius: 8px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background-image: linear-gradient(rgba(35, 31, 32, 0.8), rgba(35, 31, 32, 0.8)), linear-gradient(to left, rgb(233, 232, 232), rgb(35, 31, 32));
    background-origin: border-box;
    border-width: 6px 0px 0px;
    background-clip: content-box, border-box;
}

@media (min-width: 768px) {
    .highlight-image-new .card-new:hover .bg-eloquil-brown {
        background-color: rgba(35, 31, 32, 0.9);
        height: 100%;
    }

    .highlight-image-new .card-new:hover .bg-eloquil-brown {
        background-color: rgba(35, 31, 32, 0.9);
        height: 100%;
    }
}

.highlight-image-new .link-new {
    width: 100%;
    height: 100%;
    z-index: 999;
    text-decoration: none;
}

.highlight-image-new .card-highlight-title {
    font-family: Bogle;
    padding-top: 16px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: rgb(255, 255, 255);
}

.highlight-image-new .card-desc-2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 480px) {
    .highlight-image-new .card-desc-2 {
        -webkit-line-clamp: 2;
    }

    .highlight-image-new .card-desc-2 {
        -webkit-line-clamp: 2;
    }
}

@media (min-width: 1200px) {
    .highlight-image-new .card-desc-2 {
        -webkit-line-clamp: 2;
    }

    .highlight-image-new .card-desc-2 {
        -webkit-line-clamp: 2;
    }
}

.highlight-image-new .darken-image {
    background: rgb(0, 0, 0);
    opacity: 0.3;
}

.highlight-image-new .darken-image.opacity-0 {
    background: rgb(0, 0, 0);
    opacity: 0;
}

.highlight-image-new .darken-image.opacity-15 {
    background: rgb(0, 0, 0);
    opacity: 0.15;
}

.highlight-image-new .darken-image.opacity-30 {
    background: rgb(0, 0, 0);
    opacity: 0.3;
}

.highlight-image-new .darken-image.opacity-45 {
    background: rgb(0, 0, 0);
    opacity: 0.45;
}

.highlight-image-new .content-center {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
}

.highlight-image-new .content-center .cards-cion {
    display: none;
}

.highlight-image-new .content-center .card-icon {
    position: relative;
    color: transparent;
}

@media (min-width: 1200px) {
    .highlight-image-new .content-center .card-icon {
        font-size: 80px;
    }

    .highlight-image-new .content-center .card-icon {
        font-size: 80px;
    }
}

.highlight-image-new .card-comp-new .content-center {
    text-align: left;
}

.highlight-image-new .card-cta-2 {
    margin: 0px 0px 0px 24px;
    position: absolute;
    bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: rgb(255, 255, 255);
    display: none;
}

.highlight-image-new .highlight-2-new {
    width: 100%;
    height: 40%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    transition: height 0.15s ease 0s;
    border-width: 6px 0px 0px;
    border-style: solid;
    border-image-slice: 1;
}

.highlight-image-new .highlight-2-new .card-highlight-title {
    color: rgb(255, 255, 255);
}

.highlight-image-new .highlight-2-new .card-cta-new {
    color: rgb(255, 255, 255);
}

.highlight-image-new .highlight-2-new.gt-global-green {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(193, 252, 222), rgb(6, 242, 123));
}

.highlight-image-new .highlight-2-new.gt-granite-grey {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(215, 215, 216), rgb(96, 94, 99));
}

.highlight-image-new .highlight-2-new.gt-grey {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(237, 238, 241), rgb(185, 187, 197));
}

.highlight-image-new .highlight-2-new.gt-blue-ink {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(192, 199, 208), rgb(4, 31, 65));
}

.highlight-image-new .highlight-2-new.wmi-purple {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(208, 200, 225), rgb(67, 33, 136));
}

.highlight-image-new .highlight-2-new.wmi-pink {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(250, 196, 227), rgb(235, 20, 141));
}

.highlight-image-new .highlight-2-new.wmi-dark-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(193, 199, 202), rgb(8, 31, 44));
}

.highlight-image-new .highlight-2-new.wmi-medium-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(191, 219, 243), rgb(0, 112, 206));
}

.highlight-image-new .highlight-2-new.wmi-light-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(221, 237, 249), rgb(120, 185, 231));
}

.highlight-image-new .highlight-2-new.wmi-yellow {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(255, 240, 199), rgb(255, 194, 32));
}

.highlight-image-new .highlight-2-new.wmi-green {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(221, 239, 208), rgb(118, 192, 67));
}

.highlight-image-new .highlight-2-new.wmi-red {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(251, 206, 202), rgb(238, 59, 42));
}

.highlight-image-new .highlight-2-new.wmi-orange {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(252, 220, 200), rgb(244, 117, 33));
}

.highlight-image-new .highlight-2-new.bg-yellow {
    color: rgb(0, 0, 0) !important;
    border-image-source: linear-gradient(to left, rgb(255, 252, 191), rgb(255, 242, 0));
}

.highlight-image-new .highlight-2-new.bg-yellow .card-highlight-title {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-yellow .card-cta-new {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new-spark-yellow {
    color: rgb(0, 0, 0) !important;
    border-image-source: linear-gradient(to left, rgb(255, 240, 199), rgb(255, 194, 32));
}

.highlight-image-new .highlight-2-newyellow-tone {
    color: rgb(0, 0, 0);
    border-image-source: linear-gradient(to left, rgb(253, 249, 232), rgb(248, 230, 165));
}

.highlight-image-new .highlight-2-newozark-noir {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(192, 199, 208), rgb(4, 30, 66));
}

.highlight-image-new .highlight-2-new.bg-green {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(215, 236, 209), rgb(95, 180, 70));
}

.highlight-image-new .highlight-2-new.bg-light-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(241, 248, 252), rgb(120, 185, 231));
}

.highlight-image-new .highlight-2-new.bg-red {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(247, 198, 200), rgb(222, 28, 36));
}

.highlight-image-new .highlight-2-new.bg-orange {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(254, 216, 191), rgb(250, 100, 0));
}

.highlight-image-new .highlight-2-new.bg-teal {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(235, 249, 252), rgb(62, 197, 230));
}

.highlight-image-new .highlight-2-new.bg-labs {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(234, 233, 237), rgb(48, 41, 81));
}

.highlight-image-new .highlight-2-new.bg-black, .highlight-image-new .highlight-2-new.bg-allswell-black, .highlight-image-new .highlight-2-new.bg-barenecessities-black, .highlight-image-new .highlight-2-new.bg-parcel-black {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(229, 229, 229), rgb(0, 0, 0));
}

.highlight-image-new .highlight-2-new.bg-gray {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(241, 241, 241), rgb(117, 117, 117));
}

.highlight-image-new .highlight-2-new.bg-light-gray {
    color: rgb(0, 0, 0);
    border-image-source: linear-gradient(to left, rgb(253, 253, 253), rgb(242, 242, 242));
}

.highlight-image-new .highlight-2-new.bg-light-gray .card-highlight-title {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-light-gray .card-cta-new {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-transparent {
    color: rgb(0, 0, 0);
    border-width: 0px;
}

.highlight-image-new .highlight-2-new.bg-transparent .card-highlight-title {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-transparent .card-cta-new {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-white {
    color: rgb(0, 0, 0);
    border-image-source: linear-gradient(to left, rgb(229, 240, 250), rgb(0, 113, 206));
}

.highlight-image-new .highlight-2-new.bg-white .card-highlight-title {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-white .card-cta-new {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-sams-primary-dark-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(229, 234, 243), rgb(0, 53, 142));
}

.highlight-image-new .highlight-2-new.bg-sams-primary-main-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(229, 239, 245), rgb(0, 103, 160));
}

.highlight-image-new .highlight-2-new.bg-sams-primary-light-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(245, 251, 252), rgb(164, 219, 232));
}

.highlight-image-new .highlight-2-new.bg-sams-secondary-light-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(229, 247, 251), rgb(0, 175, 215));
}

.highlight-image-new .highlight-2-new.bg-sams-secondary-dark-gray {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(241, 243, 243), rgb(124, 135, 142));
}

.highlight-image-new .highlight-2-new.bg-sams-secondary-light-gray {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(250, 250, 250), rgb(208, 211, 212));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-dark-purple {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(237, 236, 243), rgb(81, 70, 137));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-light-purple {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(243, 239, 250), rgb(144, 99, 205));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-green {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(229, 240, 236), rgb(0, 111, 68));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-teal {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(236, 251, 246), rgb(71, 215, 172));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-red {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(254, 236, 231), rgb(250, 70, 22));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-orange {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(255, 243, 232), rgb(255, 143, 28));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-pink {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(251, 232, 241), rgb(222, 27, 115));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-yellow {
    color: rgb(0, 0, 0);
    border-image-source: linear-gradient(to left, rgb(255, 247, 229), rgb(255, 181, 0));
}

.highlight-image-new .highlight-2-new.bg-sams-supp-yellow .card-highlight-title {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-sams-supp-yellow .card-cta-new {
    color: rgb(0, 0, 0) !important;
}

.highlight-image-new .highlight-2-new.bg-jet-purple {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(237, 229, 244), rgb(79, 0, 153));
}

.highlight-image-new .highlight-2-new.bg-moosejaw-red {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(246, 234, 235), rgb(169, 52, 57));
}

.highlight-image-new .highlight-2-new.bg-modcloth-gray-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(236, 237, 239), rgb(72, 81, 95));
}

.highlight-image-new .highlight-2-new.bg-hayneedle-gray-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(236, 237, 239), rgb(69, 84, 95));
}

.highlight-image-new .highlight-2-new.bg-shoes-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(232, 234, 239), rgb(32, 53, 102));
}

.highlight-image-new .highlight-2-new.bg-bonobos-brown {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(234, 231, 231), rgb(47, 18, 20));
}

.highlight-image-new .highlight-2-new.bg-vudu-blue {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(229, 240, 250), rgb(0, 114, 206));
}

.highlight-image-new .highlight-2-new.bg-eloquil-brown {
    color: rgb(255, 255, 255);
    border-image-source: linear-gradient(to left, rgb(233, 232, 232), rgb(35, 31, 32));
}

.highlight-image-new .one-card .card-highlight-title, .highlight-image-new .two-card .card-highlight-title, .highlight-image-new .three-card .card-highlight-title, .highlight-image-new .four-card .card-highlight-title {
    font-size: 24px;
    padding-top: 16px;
}


.buttons {
    display: inline-block;
    color: rgb(1, 122, 205);
    background-color: rgb(255, 255, 255);
    min-width: 200px;
    height: 38px;
    padding-top: 5px;
    letter-spacing: normal;
    border-radius: 25px;
    text-align: center;
    border: 2px solid rgb(1, 122, 205);
}

.buttons:hover {
    background-color: rgb(1, 122, 205);
    color: rgb(255, 255, 255);
    text-decoration: none;
}