#logo {
  width: 140px;
}
#User {
  float: right !important;
  font-weight: bolder;  
  color: #ffffff;
  min-width: 200px;
  font-size: 12px;

  
}

.Navitems {
  color: #ffffff;
  line-height: 64px !important;
  font-size: 18px !important;
}
.dropdown {
  margin-right: 25px;
}
#Navheading {
  
  
  color: #ffffff;
  font-weight: bolder;
  font-size: 20px;
  overflow: hidden;
}
.sidebarlink {
  font-size: "13.5px";
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0 !important;
}

.pendingtable {
  font-family: "Roboto", Helvetica, Ariel, sans-serif !important;
  font-size: 12px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center !important;
}

#componentHeading {
  text-align: left !important;
  color: #036bc3;
  font-family: "Roboto", Helvetica, Ariel, sans-serif !important;
  font-weight: bolder;
  font-size: 24px;
  padding:12px;
}

.clsTabPressed {
  border-right: #ebf5ff 2px outset !important;
  border-top: #ebf5ff 1px outset !important;
  font-weight: bold !important;
  font-size: 12px !important;
  cursor: hand !important;
  color: #ffffff !important;  
  background-color: #023369 !important;
}

.clsTab {
  border-right: #ffffff 1px solid !important;
  border-top: #ffffff 1px solid !important;
  font-size: 12px !important;
  cursor: hand !important;
  color: #023369 !important;
  background-color: #c8d8ea !important;
}
#tabss {
  margin-left: 1% !important;
  border-bottom: 3px solid #023369 !important;
  margin-right: 1.2%;
  margin-top: 2%;
  display: block;
}

#tab {
  cursor: pointer;
  width: 150px;
  height: 35px !important;
}
.inline {
  justify-content: center;
}
.formalign {
  text-align: center;
}
.inline label {
  text-align: center !important;
  color: #036bc3 !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: bolder;
}
.formdata label {
  text-align: center !important;
  color: #036bc3 !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: bolder;
}
.datepicker {
  width: 220px;
  margin-right: 30px !important;
}
.card {
  width: 190;
  border-radius: "4px";
  color: "#FFFFFF";
  height: 120;
  background-color: "#001529";
}
.tableclass {
  text-align: center;
  padding: 1%;
  
  justify-content: center !important;
  display: flex;
  flex-wrap: row wrap;
}
.ant-table-wrapper {
  zoom: 1;
 
}
.rmtable {
  padding-top: 20px;
  text-align: center
  
}
.dashboard {
  text-align: center;
  padding: 0;
  margin-top: 120px;
  justify-content: center !important;
  display: flex;
  flex-wrap: row wrap;
}

.tree {
  font-weight: bold;
  color: black;
}



.pendingtable .ant-table-header-column {
  color: #036bc3 !important;
  text-align: center;
  justify-content: center;
  word-wrap: none;
  font-family: " Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  font-size: 13px !important;
}
.pendingtable tr:nth-child(2n + 1) td {
  background-color: #eee !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 0px !important;
  overflow-wrap: break-word;
}

.datefilters {
  display: block;
  margin: 20px 0px;
  
  color: #036bc3 !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: 600;
  font-size: 12px !important;
}
.datefilter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
  
  color: #036bc3 !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: 600;
  font-size: 12px !important;
}s
.daterangelabel {
  margin: 0px 5px;
}
.ant-btn-success {
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  background-color: #09c021 !important;
  color: #ffffff !important;
  
}
.ant-btn-disabled {
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  background-color: gray !important;
  color: #ffffff !important;
  
}
.modalbtn {
  margin-left: 550px;
  margin-bottom: 2%;
}

.ant-modal-body {
  padding: 0 15px 0 15px !important;
}
.ant-form-explain {
  min-height: 0px !important;
}
.btns {
  border-radius: 0px !important;
}


.formdata {
  margin-left: 1%;
  font-size: bolder !important;
}
.ant-form-explain,
.ant-form-extra {
  font-size: 9px !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #001529 !important;
}
.dashboardbtn {
  margin: 29px 10px 10px 10px;
}
#selects {
  width: 220px;
}
#select {
  width: 150px;
}
.dashboardlink {
  color: #ffffff !important;
}
.ant-form-item label {
  color: #036bc3 !important;
}
.card {
  display: flex;
  flex-direction: column;
  width: 230px !important;
  font-size: "bold" !important;
  font-size: 14px !important;
  margin-right: 21px;
  border-radius: 5px !important;
  padding: 10px 0 0 0 !important;
  color: #ffffff !important;
  height: 160px !important;
  background-color: #001529;
}
.card h2 {
  color: #ffffff !important;
}
.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 200px !important;
  overflow-x: hidden;
}
.myclass thead > tr:first-child > th:first-child {
  background-color: #001529 !important;
  color: #ffffff !important;
  text-align: center;
}
.myclass thead > tr:nth-child(2) > th:nth-child(-n + 4) {
  background-color: #001529 !important;
  color: #ffffff !important;
  text-align: center;
}
.myclass thead > tr:nth-child(1) > th {
  background-color: #036bc3 !important;
  color: #ffffff !important;
  text-align: center;
}
.myclass thead > tr:nth-child(2) > th:nth-child(n + 4) {
  background-color: #036bc3 !important;
  color: #ffffff !important;
  text-align: center;
}
.myclass thead > tr:first-child > th:last-child {
  background-color: #09c021 !important;
  color: #ffffff !important;
  text-align: center;
}
.myclass tbody > tr > td:nth-child(3) {
  background-color: #ff6347 !important;
}
.myclass tbody > tr > td:nth-child(6) {
  background-color: #ff6347 !important;
}
.ant-modal-title {
  text-align: center !important;
  color: #036bc3 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Roboto", Helvetica, Ariel, sans-serif !important;
}
.resolveclass thead > tr:first-child > th:nth-child(2) {
  background-color: #001529 !important;
  color: #ffffff !important;
  text-align: center;
}
.resolveclass thead > tr:nth-child(2) > th:nth-child(-n + 3) {
  background-color: #001529 !important;
  color: #ffffff !important;
  text-align: center;
}
.resolveclass thead > tr:nth-child(1) > th:nth-child(3) {
  background-color: #036bc3 !important;
  color: #ffffff !important;
  text-align: center;
}
.resolveclass thead > tr:nth-child(2) > th:nth-child(n + 3) {
  background-color: #036bc3 !important;
  color: #ffffff !important;
  text-align: center;
}
.resolveclass thead > tr:first-child > th:last-child {
  background-color: #09c021 !important;
  color: #ffffff !important;
  text-align: center;
}
.ant-layout-header {
  padding: 0 20px !important;
  width: 100%;
  height: 10% !important;
}
.daterange {
  
  margin-left: 1%;
  display: flow-root;

  
}
.Button {
  float: right;
  margin-right: 5%;
  margin-top: 5px;
}
.Button1 {
  float: right;
  margin-right: 2%;
  
  margin-top: 5px;
}
.retrieve {
  float: right !important;
}
.inputfield .checkbox {
  width: 200px !important;
  height: 30px !important;
}

.ant-modal-footer {
  padding: 0 !important;
}
.ant-modal-header {
  padding: 8px 24px !important;
}
.wrapper {
  margin-bottom: 10px;
}
.mytitle .ant-tree-child-tree .ant-tree-title {
  color: blue !important;
}
.tree .ant-tree-switcher {
  color: navy !important;
}
.tree {
  color: navy !important;
}
.tree .ant-tree-title {
  color: navy !important;
  font-weight: 500;
}
.tab {
  height: 200;
}
.hierarchymodal {
  min-height: 300px;
  display: flex;
  min-width: 700px;
  justify-content: center;
}
.spinner {
  text-align: center;
}
.container {
  display: flex !important;
  flex-direction: row;
  margin-left: 1% !important;
}
.container.flex{
  flex-wrap: wrap;
}
.container div {
  margin-right: 6px !important;
}
.ant-select-dropdown-menu {
  max-height: 100px !important;
}
.ant-select-selection--multiple {
  max-height: 32px !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
.sidebar {
  width: 50px;
  background-color: #036bc3;
  color: aliceblue;
}
.cstyle {
  width: 300px !important;
}
.ant-table table {
  text-align: center !important;
}
.ant-table-bordered .ant-table-thead > tr > th {
  text-align: center !important;
}
.ant-table {
  font-size: 12px !important;
}
.headerbar {
  padding-bottom: 1%;
  color: #757e8a !important;
}
/* ----------------------------------- */
.topnav {
  overflow: hidden;
  
  background-color: #036bc3;
}

.topnav div {  
  float: right;
  display: block;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 12px;
  
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}


.click-to-login {
  position: absolute;
  top: 45%;
  left: 44%;
}


.align-loader {
  position: relative;
  margin-top: 10%;
  left: 45%;
}

.align-center {
  position: absolute;
  top: 48%;
  left: 48%;
  z-index: 2;
}

.cpdaBtn{
  margin: 0 10px;
}
.ant-card.center{
  width: 30%;
    margin-left: 50%;
    margin-top: 10%;
    transform: translate(-50%);
}
form.cpdaForm {
     margin-left: 2%!important;
    width: 100%;
}
form.itemForm .ant-form-item{
 margin-left: 1%;
 width: 23% !important;
}
.ant-row.ant-form-item.formItem {
    width: 32%;
}
.ag-theme-alpine {
  /* height: 500px; */
  margin: 0  15px;
}
.ag-theme-alpine.small-table{
  height: 125px;
  margin: 60px 15px;
}
.ag-theme-alpine.ag-popup {
  height:0;
}
.backBtn{
  margin: 10px;
}
button.ant-btn.backBtn.nav.ant-btn-primary.ant-btn-background-ghost{
  border-color: transparent;
}
.pageHeading{
  width:50%;
  float: left;
   color: #036bc3;
    font-size: 24px;
    font-weight: bolder;
    padding: 12px 12px 0;
    text-align: left!important;
    margin-bottom: 0;
}
.backBtn.nav{
  float: right;
}
.mainForm .ant-form{
width:100%;
}
.excelBtn{
    background-color: #1890ff;
    border: none;
    color: white;
    border-radius: 3px;
    font-weight: 500 !important;
    margin-left: 10px!important;
    padding: 6px 8px 9px;
}
.navBtn{
    border: none;
    background: none;
    color: #0b7deb;
}
.height-60{
  height: 60px;
}
.ag-layout-auto-height .ag-center-cols-clipper{
    min-height: 50px !important;
}
button.ant-btn.createBtn.ant-btn-primary{  margin-left: 15px;
  background-color: #52c41a;
  border-color: #52c41a;
}
.createBtn.margin-bottom{
  margin-bottom: 15px;
}
.ant-menu-dark{
  background-color: #11224b;
}
button.ant-btn.createShipperBtn.ant-btn-primary{  
  background-color: #52c41a;
  border-color: #52c41a;
  margin: 5px 225px -30px 0px;
  top: -40px;
  position: relative;
  float: right;
}
.createShipperBtn.margin-bottom{
  margin-bottom: 15px;
}
button.ant-btn.createShipperBtnDisabled.ant-btn-primary{  
  border-color: #c4231a;
  margin: 5px 225px -30px 0px;
  top: -40px;
  position: relative;
  float: right;
}
.createShipperBtnDisabled.margin-bottom{
  margin-bottom: 15px;
}
button.ant-btn.updateShipperBtn.ant-btn-primary{  
  background-color: #6c757d;
  border-color: #6c757d;
  float: right;
  margin: 5px 624px -64px 0;
  position: relative;
  top: -23px;
}
.updateShipperBtn.margin-bottom{
  margin-bottom: 15px;
}
.contentItemMsg {
  margin: 0px 400px 0px 0px;
  font-Weight: bold;
  top: -35px;
  background-color: #17a2b8;
  color: #fff;
  border-color: #17a2b8;
  position: relative;
  padding: 10px;
  float: right;
}
.totalUnitQuantity
{
  float: right;
  margin-right: 150px;
  color: #17a2b8;
  font-weight: bold;
}
.totalOrderableCost
{
  float: right;
  margin-right: 24px;
  color: #28a745;
  font-weight: bold;
}